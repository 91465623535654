import React, { useContext, useMemo } from "react";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import CheckoutItem from "../../components/common/CheckoutItem";
import { useUpdateCart, useNavigate, usePriceFormat } from "../../hooks";
import { CartContext } from "../../context";
import _ from "lodash";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useAlert } from "react-alert";
import Icofont from "../../components/IcoFont";
import { useTrans } from "../../hooks";

const CartSummary = () => {
  const cartContext = useContext(CartContext);
  const alert = useAlert();
  const transMsg = useTrans();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { priceFormat } = usePriceFormat();
  const { onUpdate: updateCart } = useUpdateCart();

  const onPay = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    updateCart("PAYMENT");
    if (_.isEmpty(cartContext.cartPayment)) return;

    cartContext.setCartBeingProcessed(true);
    const result = await stripe.confirmCardPayment(
      cartContext.cartPayment.clientSecret,
      {
        receipt_email: `${cartContext.cartEmail}`,
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${cartContext.billingAddress?.firstName} ${cartContext.billingAddress?.lastName}`,
            email: `${cartContext.cartEmail}`,
            address: {
              // city: `${cartContext.billingAddress?.city}`,
              // country: `${cartContext.billingAddress.countryCode}`,
              line1: `${cartContext.billingAddress?.address_1}`,
              // line2: `${cartContext.billingAddress?.address_2}`,
              postal_code: `${cartContext.billingAddress?.postalCode}`,
              // state: `${cartContext.billingAddress?.province}`,
            },
            phone: `${cartContext.billingAddress?.phone}`,
          },
        },
      }
    );

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(JSON.stringify(result.error));
      alert.error(result.error.message, {
        timeout: 0,
      });
      cartContext.setCartBeingProcessed(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent?.status === "succeeded") {
        navigate(
          `/checkout/confirm?token=${cartContext.cartPayment?.orderToken}`
        );
      }
    }
  };

  const popoverSecure = useMemo(() => {
    return (
      <Popover id="popover-basic" className="w-100">
        <Popover.Content className="py-3 px-4">
          {transMsg("yourTransactionIsSecureMessage", {
            h6: (str) => <h6>{str}</h6>,
          })}
        </Popover.Content>
      </Popover>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledButton = useMemo(() => {
    return (
      !cartContext.isCartComplete ||
      cartContext.cartBeingProcessed ||
      (cartContext.orderType === "delivery" && !cartContext.canDeliver)
    );
  }, [
    cartContext.isCartComplete,
    cartContext.cartBeingProcessed,
    cartContext.orderType,
    cartContext.canDeliver,
  ]);
  return (
    <>
      <div className="generator-bgOFF rounded shadow-smOFF mb-4 p-1 osahan-cart-item">
        <div className="d-flex mb-4 osahan-cart-item-profile"></div>
        <br />
        <Button
          disabled={disabledButton}
          // variant="success"
          className=" btn-block btn-lg round-btn btn-height"
          onClick={onPay}
        >
          {transMsg("placeOrder")}
        </Button>
        <div className="text-center py-1">
          <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={popoverSecure}
          >
            <Button variant="link" style={{ boxShadow: "none" }}>
              <Icofont icon="lock" /> {transMsg("secureTransaction")}
            </Button>
          </OverlayTrigger>
        </div>
        <div className="bg-white rounded shadow-smOFF my-2 pt-2 ">
          {!_.isEmpty(cartContext.cartLineItems) && (
            <>
              <h6 className="mt-3 text-black-50">
                <div className="row ">
                  <div className="col-12">
                    <label
                      className="my-1 mr-sm-2 mb-2 h6-text"
                      htmlFor="inlineFormInputSelectTime"
                    >
                      {transMsg("orderSummary")}:
                    </label>
                  </div>{" "}
                </div>{" "}
              </h6>
              <>
                {_.map(cartContext.cartLineItems, (v, k) => {
                  return (
                    <React.Fragment key={k}>
                      <CheckoutItem
                        itemName={v.title}
                        price={v.unitPrice}
                        priceUnit="$"
                        id={`${v.id}`}
                        quantity={v.quantity}
                        editable={false}
                        minValue={0}
                        maxValue={99}
                        choices={v.choices}
                        note={v.note}
                        getValue={() => {}}
                      />
                    </React.Fragment>
                  );
                })}

                {!_.isEmpty(cartContext.cartNote) && (
                  <>
                    <h6 className="mt-3 text-black-50">
                      <div className="row ">
                        <div className="col-12">
                          <label
                            className="my-1 mr-sm-2 mb-2 h6-text"
                            htmlFor="inlineFormInputSelectTime"
                          >
                            {transMsg("orderInstructions")}:
                          </label>
                        </div>{" "}
                      </div>{" "}
                    </h6>
                    <div className="mt-1 mb-0 text-black capitalize">
                      {cartContext.cartNote}
                    </div>
                  </>
                )}
              </>
            </>
          )}
        </div>
        <div className="mb-2 bg-white rounded p-2 clearfix"></div>
        <hr />
        <div className="mb-2 bg-white rounded p-2 clearfix">
          <p className="mb-1 font-14 text-dark">
            {transMsg("itemTotal")}{" "}
            <span className="float-right text-dark">
              {priceFormat(cartContext.cartTotals?.subtotal)}
            </span>
          </p>
          {cartContext.cartTotals?.discountTotal > 0 && (
            <p className="mb-1 font-14 text-dark">
              {transMsg("discount")}{" "}
              <span className="float-right text-dark">
                ({cartContext.cartOffer?.code && cartContext.cartOffer?.code}) -{" "}
                {priceFormat(cartContext.cartTotals?.discountTotal)}
              </span>
            </p>
          )}
          {cartContext.orderType === "delivery" && (
            <p className="mb-1">
              {transMsg("delivery")}{" "}
              <span className="float-right text-dark">
                {priceFormat(cartContext.cartTotals?.deliveryFees)}
              </span>
            </p>
          )}
          {cartContext.cartTotals?.taxTotal > 0 && (
            <p className="mb-1">
              {transMsg("tax")}{" "}
              <span className="float-right text-dark">
                {priceFormat(cartContext.cartTotals?.taxTotal)}
              </span>
            </p>
          )}
          {cartContext.cartTip > 0 && (
            <p className="mb-1">
              {transMsg("tip")}{" "}
              <span className="float-right text-dark">
                {priceFormat(cartContext.cartTip)}
              </span>
            </p>
          )}
          <hr />
          <h6 className="font-weight-bold mb-0">
            {transMsg("total")}{" "}
            <span className="float-right">
              {priceFormat(cartContext.cartTotals?.total)}
            </span>
          </h6>
        </div>
      </div>
    </>
  );
};

export default CartSummary;
