import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useTrans } from "../../hooks";
import { navigate } from "gatsby";
import { Button, Form } from "react-bootstrap";
import { CartContext } from "../../context";
import { useApplyOffer, useRemoveOffer } from "../../mutations";
import { useAlert } from "react-alert";
import Icofont from "../IcoFont";

const ApplyOffer = () => {
  const transMsg = useTrans();
  const { cartOffer, setCartOffer, cartToken } = useContext(CartContext);
  const {
    data: applyOfferData,
    error: applyOfferError,
    setData: setApplyOfferVariable,
  } = useApplyOffer();
  const { data: removeOfferData, setData: setRemoveOfferVariable } =
    useRemoveOffer();

  const [code, setCode] = useState(cartOffer?.code || "");
  const alert = useAlert();

  const onApply = () => {
    if (_.isEmpty(code)) return;
    if (_.isEmpty(cartToken)) return;

    console.log(code, cartToken);
    setApplyOfferVariable({
      variables: {
        code,
        cartToken,
      },
    });
  };
  const onRemoveOffer = () => {
    if (_.isEmpty(cartToken)) return;

    console.log(cartToken);
    setRemoveOfferVariable({
      variables: {
        cartToken,
      },
    });
  };
  useEffect(() => {
    console.log(cartOffer, applyOfferData?.applyOffer);
    if (_.isEmpty(applyOfferData?.applyOffer?.cart?.offer)) return;
    setCartOffer(applyOfferData?.applyOffer?.cart.offer);
    navigate(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyOfferData]);

  useEffect(() => {
    if (!_.isNull(removeOfferData?.removeOffer?.cart?.offer)) return;
    setCartOffer({});
    navigate(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeOfferData]);

  useEffect(() => {
    console.log("error", applyOfferError);
    if (_.isEmpty(applyOfferError)) return;
    alert.error(transMsg("offerCannotBeApply"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyOfferError]);

  return (
    <>
      <div className="row">
        <div className="col-8">
          <div className="form-label-group">
            <Form.Control
              type="text"
              id="offerCodeInput"
              className="text-uppercase"
              placeholder={transMsg("discountCode")}
              name="offerCode"
              value={code}
              onChange={({ target: { value } }) => {
                setCode(value.toUpperCase());
              }}
            />
            <Form.Label htmlFor="offerCodeInput">
              {transMsg("discountCode")}
            </Form.Label>
          </div>
        </div>
        <div className="col-4 text-center">
          <Button
            variant="success"
            style={{ height: "51px" }}
            className="w-100"
            disabled={_.isEmpty(code)}
            onClick={onApply}
          >
            {transMsg("apply")}
          </Button>
        </div>
        {cartOffer?.code && (
          <div className="col-12">
            <span className="bg-success p-2 ">
              <Icofont icon="sale-discount" />
              <span className="px-2" style={{ color: "#fff" }}>
                {cartOffer.code}
              </span>
              <Button
                variant="link"
                className="p-0"
                onClick={onRemoveOffer}
                style={{ color: "black", fontSize: 20, fontWeight: 700 }}
              >
                <Icofont icon="close-line" />
              </Button>
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default ApplyOffer;
