import React, { useContext, useMemo } from "react";

import Layout from "../../components/Layout";
import { LocationsContext } from "../../context";
import { Container } from "react-bootstrap";
import useSyncCart from "./useSyncCart";
import { Elements } from "@stripe/react-stripe-js";
import getStripe from "../../utils/stripejs";
import CartSummary from "./cartSummary";
import CheckoutPayment from "./payment";
import CannotDeliverMessage from "../../components/CannotDeliverMessage";

const CheckoutPlaceOrder = ({ pageContext }) => {
  const { seoData, layoutProps } = pageContext;
  const { cartInSync } = useSyncCart();
  const { selectedLocation } = useContext(LocationsContext);

  const stripePromise = useMemo(() => {
    if (selectedLocation?.stripeAccountId)
      return getStripe(selectedLocation?.stripeAccountId);
  }, [selectedLocation?.stripeAccountId]);

  return (
    <Layout
      seo={seoData}
      hasMultipleShareLocations={layoutProps?.hasMultipleShareLocations}
      restaurant={layoutProps?.restaurant}
      fixedHeader
      showLocationInfo
    >
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <Container>
            <div className="p-5 mt-5">
              <div className="row mt-5">
                <div className="col-md-12  col-lg-6 m-auto">
                  <div
                    style={{
                      opacity: cartInSync ? 0.35 : 1,
                      pointerEvents: cartInSync ? "none" : "auto",
                    }}
                  >
                    <CannotDeliverMessage showAlert />
                    {/* PAYMENT */}
                    <div className="row">
                      <div className="col-12">
                        <CheckoutPayment />
                      </div>
                    </div>
                    <CartSummary restaurantLogo={layoutProps.restaurant.logo} />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Elements>
      )}
    </Layout>
  );
};

export default CheckoutPlaceOrder;
