import React, { useCallback, useContext, useMemo, useState } from "react";
import { CartContext } from "../../context";
import { ButtonGroup, Button, Form } from "react-bootstrap";
import _ from "lodash";
import { navigate } from "gatsby";
import { usePriceFormat } from "../../hooks";
import { useTrans } from "../../hooks";

const UpdateCheckoutTip = () => {
  const transMsg = useTrans();
  const { cartTip, setCartTip, cartTotals } = useContext(CartContext);
  const [isEdit, setIsEdit] = useState(false);
  const calTip = useCallback(
    (num) => {
      if (_.isEmpty(cartTotals)) return 0;
      return _.round(
        (cartTotals?.subtotal + cartTotals?.deliveryFees) * num,
        2
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartTotals.subtotal + cartTotals.deliveryFees]
  );

  const TIPS = useMemo(() => {
    return {
      _10: calTip(0.1),
      _15: calTip(0.15),
      _20: calTip(0.2),
      _25: calTip(0.25),
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { priceFormat } = usePriceFormat();

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h5 style={{ color: "rgb(41, 41, 41)" }}>
            {" "}
            {_.upperCase(transMsg("tip"))}:
          </h5>
        </div>
        <div className="col-12">
          <ButtonGroup className="mb-2 w-100">
            <Button
              variant="outline-success"
              active={TIPS._10 === cartTip}
              onClick={() => {
                setCartTip(TIPS._10);
                navigate(0);
              }}
            >
              <div className="row">
                <div className="col-12">10%</div>
                <div className="col-12" style={{ color: "#4a4a4a" }}>
                  {priceFormat(TIPS._10)}
                </div>
              </div>
            </Button>
            <Button
              active={TIPS._15 === cartTip}
              variant="outline-success"
              onClick={() => {
                setCartTip(TIPS._15);
                navigate(0);
              }}
            >
              <div className="row">
                <div className="col-12">15%</div>
                <div className="col-12" style={{ color: "#4a4a4a" }}>
                  {priceFormat(TIPS._15)}
                </div>
              </div>
            </Button>
            <Button
              active={TIPS._20 === cartTip}
              variant="outline-success"
              onClick={() => {
                setCartTip(TIPS._20);
                navigate(0);
              }}
            >
              <div className="row">
                <div className="col-12">20%</div>
                <div className="col-12" style={{ color: "#4a4a4a" }}>
                  {priceFormat(TIPS._20)}
                </div>
              </div>
            </Button>
            <Button
              variant="outline-success"
              active={TIPS._25 === cartTip}
              onClick={() => {
                setCartTip(TIPS._25);
                navigate(0);
              }}
            >
              <div className="row">
                <div className="col-12">25%</div>
                <div className="col-12" style={{ color: "#4a4a4a" }}>
                  {priceFormat(TIPS._25)}
                </div>
              </div>
            </Button>
            <Button
              variant="success"
              active={isEdit}
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            >
              {transMsg("edit")}
            </Button>
          </ButtonGroup>
        </div>
        {isEdit && (
          <>
            <div className="col-12">
              <div className="form-label-group">
                <Form.Control
                  type="number"
                  id="inputTipNumber"
                  placeholder="TIP"
                  name="tip"
                  value={cartTip > 0 ? cartTip : ""}
                  min={0}
                  onChange={({ target: { value } }) => {
                    setCartTip(_.toNumber(value));
                  }}
                />
                <Form.Label htmlFor="inputTipNumber">
                  {transMsg("tip")} <span className="text-danger">*</span>
                </Form.Label>
              </div>
            </div>
            <div className="col-12 text-right">
              <Button
                variant="outline-dark"
                className="text-right"
                onClick={() => {
                  navigate(0);
                }}
              >
                {transMsg("update")}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UpdateCheckoutTip;
