/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./cardSectionStyles.css";
import { Form } from "react-bootstrap";

const CardSection = () => {
  return (
    <Form.Group>
      <Form.Label
        className="mb-2 font-14 ml-1"
        style={{ fontWeight: "normal" }}
      >
        {" "}
        {/* Card details */}
      </Form.Label>
      <CardElement options={{ hidePostalCode: true }} />
      {/* <CardNumberElement />
      <CardExpiryElement /> */}
      {/* <PaymentRequestButtonElement /> */}
    </Form.Group>
  );
};

export default CardSection;
